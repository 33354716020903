type PropsType = {
  color?: string;
};

const At = (props: PropsType): JSX.Element => {
  const { color = '#07fe28' } = props;

  return (
    <svg width="33" height="31" viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0528 28.9758C15.7685 28.0691 7.18928 26.1086 3.45016 20.3683C0.869082 16.4058 1.90848 11.9656 5.18487 8.93579C9.25865 5.16864 14.214 2.74754 19.7729 2.21174C22.2386 1.97408 26.3253 1.50081 28.0665 3.73167C30.3211 6.62048 30.722 10.9676 30.7429 14.4868C30.7533 16.2513 30.7042 18.8017 29.5533 20.3188C28.6158 21.5546 26.9667 20.5783 26.1335 19.7901C23.6571 17.4476 22.0443 13.9272 21.3589 10.6375C21.0012 8.92015 21.616 10.462 21.8215 11.067C22.9177 14.2948 22.9215 19.1017 20.0372 21.4091C18.6815 22.4938 16.2469 22.2133 14.7836 21.5083C11.8626 20.1009 12.0366 16.9352 12.4376 14.1069C12.729 12.0516 13.7421 9.38828 15.874 8.58885C17.1078 8.12616 18.7306 8.39059 20.0372 8.39059"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default At;
