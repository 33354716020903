const Mail = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 29">
      <g>
        <path
          d="M35,0H4A4,4,0,0,0,0,4V25a4,4,0,0,0,4,4H35a4,4,0,0,0,4-4V4A4,4,0,0,0,35,0ZM6,3H33a2.94,2.94,0,0,1,1,.17L19.69,14.74,5.3,3.08A3.27,3.27,0,0,1,6,3ZM36,23a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V6a2.76,2.76,0,0,1,.12-.82L18.8,17.87a1.47,1.47,0,0,0,.94.34,1.5,1.5,0,0,0,1.17-.56h0L36,5.45A3.31,3.31,0,0,1,36,6Z"
          transform="translate(0 0)"
          fill="#fff"
          stroke="none"
        />
        <path
          d="M34,3.17,19.69,14.74,5.3,3.08A3.27,3.27,0,0,1,6,3H33A2.94,2.94,0,0,1,34,3.17Z"
          transform="translate(0 0)"
          fill="none"
          stroke="none"
        />
        <path
          d="M36,6V23a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V6a2.76,2.76,0,0,1,.12-.82L18.8,17.87a1.47,1.47,0,0,0,.94.34,1.5,1.5,0,0,0,1.17-.56l0,0L36,5.45A3.31,3.31,0,0,1,36,6Z"
          transform="translate(0 0)"
          fill="none"
          stroke="none"
        />
      </g>
    </svg>
  );
};

export default Mail;
